const listLevels = {
  ZERO: '0',
  ONE: '1',
  ONE_A: '1A',
  ONE_B: '1B',
  ONE_C: '1C',
  ONE_D: '1D',
  TWO: '2',
  THREE: '3',
  FOUR: '4',
  FIVE: '5',
  SIX: '6',
  PC: 'PC',
  EXAMPLE: 'example',
  ALL: 'ALL',
};

export enum LEVEL_NAMES {
  ONE_A = '1A',
  ONE_B = '1B',
}

export type LEVEL_NAME = typeof LEVEL_NAMES[keyof typeof LEVEL_NAMES];

export enum LEVEL_STEPS {
  'contactInformation' = 'contactInformation',
  'highSchool' = 'highSchool',
  'academics' = 'academics',
  'studyAbroad' = 'studyAbroad',
  'researchAndPrograms' = 'researchAndPrograms',
  'additionalDetails' = 'additionalDetails',
  'coursework' = 'coursework',
  'collegiateAthletics' = 'collegiateAthletics',
  'additionalDetailsCollegiateAthletics' = 'additionalDetailsCollegiateAthletics',
  'technicalSkill' = 'technicalSkill',
  'languages' = 'languages',
  'certificationsAndLicences' = 'certificationsAndLicences',
  'personalInterests' = 'personalInterests',
  'diversity' = 'diversity',
}

export default listLevels;

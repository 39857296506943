import {
  DIVERSITY_TEMPLATE,
  DIVERSITY_TEMPLATE_CATEGORY,
  DIVERSITY_TEMPLATE_OTHER_STATUS,
  DIVERSITY_TEMPLATE_OTHER_STATUS_SECOND,
  DIVERSITY_TEMPLATE_STATUS,
} from 'constant/templates';

const diversityData = {
  id: 'diversity',
  control: 'diversity',
  label: 'Submitted Diversities',
  template: DIVERSITY_TEMPLATE,
  generateFields: {
    statusName: ['status', 'name'],
    categoryName: ['category', 'name'],
  },
  optionalControls: ['statusName', 'status', 'categoryName', 'includeOnResume', 'otherStatus'],
  form: [
    {
      id: 1,
      type: 'customSelect',
      control: 'category',
      placeholder: 'Diversity',
      endpoint: 'diversities',
      onChangeSetValue: [
        { control: 'status', newValue: '' },
        { control: 'otherStatus', newValue: '' },
      ],
    },
    {
      id: 2,
      type: 'customSelect',
      control: 'status',
      placeholder: 'More Detail/Optional (Write In)',
      endpoint: DIVERSITY_TEMPLATE_CATEGORY,
      onChangeSetValue: [{ control: 'otherStatus', newValue: '' }],
      style: { flex: 1, display: DIVERSITY_TEMPLATE_STATUS },
    },
    {
      id: 3,
      type: 'input',
      control: 'otherStatus',
      label: 'Enter Other',
      style: { flex: 1, display: DIVERSITY_TEMPLATE_OTHER_STATUS },
      validationType: 'string',
      validations: [
        { type: 'required', params: ['This field is required.'] },
        { type: 'trim' },
        { type: 'max', params: [100, 'The max length is 100 characters.'] },
      ],
    },
    {
      id: 4,
      type: 'input',
      control: 'otherStatus',
      label: 'More Detail/Optional (Write In)',
      style: { flex: 1, display: DIVERSITY_TEMPLATE_OTHER_STATUS_SECOND },
    },
    {
      id: 5,
      type: 'check',
      control: 'includeOnResume',
      centered: true,
      label: 'INCLUDE ON RESUME & PROFILE',
      default: false,
    },
  ],
};

export default diversityData;

import { useFormikContext } from 'formik';
import React from 'react';

import { ReactComponent as MoveDownIcon } from 'assets/svg/v2/levels/arrow_down.svg';
import { ReactComponent as MoveUpIcon } from 'assets/svg/v2/levels/arrow_up.svg';
import { ReactComponent as LinkIcon } from 'assets/svg/v2/levels/link_chain.svg';
import { ReactComponent as RemoveIcon } from 'assets/svg/v2/levels/remove_red.svg';
import { ReactComponent as UnlinkIcon } from 'assets/svg/v2/levels/unlink.svg';
import { Checkbox, Label } from 'components/v2/forms/components';
import { ExperienceLine } from 'store/v2/level/types';

import { getDefaultLine, MAX_CHARS } from '../../1A/components/additional-details/form-config';
import {
  CharactersCounter,
  CombineButton,
  HeaderAction,
  RemoveButton,
  Textarea,
  Textbox,
  TextboxHeader,
  TextboxWrapper,
  TextCheckbox,
} from './LineTextbox.styled';

interface ExperienceTextboxProps {
  lines: ExperienceLine[];
  baseName: string;

  index: number;
  remove: <T>(index: number) => T | undefined;
  swap: (indexA: number, indexB: number) => void;
  // the any type is there to align with type from formik
  replace: (index: number, value: any) => void;
  insert: (index: number, value: any) => void;
}

const TWO_LINES_MAX_CHARS = MAX_CHARS * 2;

const LinesTextbox = ({ baseName, index, remove, swap, replace, insert, lines }: ExperienceTextboxProps) => {
  // no type is provided here as its a generic component and does not
  // depend on parent's structure at all as long as it has lines
  const { handleBlur, handleChange } = useFormikContext();

  const lineValue = lines[index];
  const lineName = `${baseName}[${index}]`;
  const maxCharsLimit = lineValue.isMerge ? MAX_CHARS * 2 : MAX_CHARS;

  const placeholder =
    index === 0
      ? 'Start with an active verb; provide specific details about the project or scholarship, the purpose, and the intended or achieved learnings or results.'
      : 'If you need more characters for the above bullet, use the chain link icon to combine bullets.';

  const handleSort = React.useCallback(
    (index: number, isUpward: boolean) => {
      let sortIndex: number;
      if (isUpward) {
        sortIndex = index - 1;
      } else {
        sortIndex = index + 1;
      }
      swap(index, sortIndex);
    },
    [lines]
  );

  const handleTextValueChange = React.useCallback(
    event => {
      if (event && event?.target?.value?.length <= maxCharsLimit) handleChange(event);
    },
    [maxCharsLimit]
  );

  const handleMerge = (index: number) => {
    const nextLine = index + 1;

    let textValueToMerge = remove ? remove<ExperienceLine>(nextLine)?.value : undefined;

    if (textValueToMerge === undefined) {
      textValueToMerge = lines[nextLine].value;
    }

    const updatedTextValue = `${lineValue?.value} ${textValueToMerge}`.slice(0, TWO_LINES_MAX_CHARS);

    const newValue: ExperienceLine = {
      ...lineValue,
      value: updatedTextValue,
      isMerge: true,
      enabled: lines[nextLine].enabled && !lineValue.enabled ? false : lineValue.enabled || lines[nextLine].enabled,
    };

    replace(index, newValue);
  };

  const handleUnMerge = (index: number) => {
    const currentLineValue = lineValue?.value?.slice(0, MAX_CHARS);
    const nextLineValue =
      lineValue?.value?.length > MAX_CHARS ? lineValue?.value?.slice(MAX_CHARS, TWO_LINES_MAX_CHARS) : '';

    const currentLine = { ...getDefaultLine(), value: currentLineValue, enabled: lineValue?.enabled };
    const nextLine = { ...getDefaultLine(), value: nextLineValue, enabled: lineValue?.enabled };

    replace(index, currentLine);
    insert(index + 1, nextLine);
  };

  const lineIndex = React.useMemo(() => {
    const unmergedLinesBefore = lines.slice(0, index).filter(l => !l.isMerge);
    const mergedLinesBefore = lines.slice(0, index).filter(l => l.isMerge);
    return unmergedLinesBefore.length + mergedLinesBefore.length * 2 + 1;
  }, [lines]);

  const isRequired = (lineIndex === 2 || lineIndex === 1) && (index === 0 || index === 1);

  return (
    <TextboxWrapper>
      <TextboxHeader className="row">
        <div className="col-8">
          <Label required={isRequired}>
            Line {lineIndex}
            {lineValue.isMerge && `, ${lineIndex + 1}`}
          </Label>
        </div>
        <div className="col-4 line-actions-box">
          {lineValue.isMerge ? (
            <HeaderAction type="button" onClick={() => handleUnMerge(index)}>
              <UnlinkIcon />
            </HeaderAction>
          ) : null}

          <HeaderAction type="button" onClick={() => handleSort(index, index !== 0)}>
            {index !== 0 ? <MoveUpIcon /> : <MoveDownIcon />}
          </HeaderAction>
        </div>
      </TextboxHeader>

      <Textbox isMerge={lineValue.isMerge}>
        <TextCheckbox>
          <Checkbox name={`${lineName}.enabled`} checked={lineValue.enabled} onChange={handleChange} />
        </TextCheckbox>

        <RemoveButton type="button" onClick={!lineValue.isMerge && lineIndex > 2 ? () => remove(index) : undefined}>
          <RemoveIcon aria-hidden="true" />
        </RemoveButton>

        <CharactersCounter>
          {lineValue?.value?.length}/{maxCharsLimit}
        </CharactersCounter>
        <Textarea
          label=""
          name={`${lineName}.value`}
          placeholder={placeholder}
          value={lineValue.value}
          onChange={event => handleTextValueChange(event)}
          onBlur={handleBlur}
        />
      </Textbox>

      {/* 
        Do not show Link Icon if one of the following
        1. No more than 4 lines
        2. Not for the last line
        3. If the current line is  merged
        4. If the next line is merged
       */}
      {index < 4 && index !== lines.length - 1 && !lineValue.isMerge && !lines[index + 1].isMerge ? (
        <CombineButton type="button" onClick={() => handleMerge(index)}>
          <LinkIcon /> Connect two lines for more available characters.
        </CombineButton>
      ) : null}
    </TextboxWrapper>
  );
};

export default LinesTextbox;

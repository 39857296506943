import { useFormik } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as CancelSvg } from 'assets/svg/cancel.svg';
import { ReactComponent as CheckboxSvg } from 'assets/svg/checkbox.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { ReactComponent as CancelYellowSvg } from 'assets/svg/v2/levels/cancel-yellow.svg';
import { ButtonVariant, ForwardButton } from 'components/v2/buttons';
import { Form, FormActionButtonsRow, FormRow, Input, Select } from 'components/v2/forms/components';
import { StyledCheckbox } from 'components/v2/forms/components/checkbox/Checkbox.styled';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS, levelConfigs, listLevels, levelBRoutes } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { isTechnicalSkillsLoadingSelector } from 'store/v2/level/selectors';
import { TechnicalSkillsFormValues } from 'store/v2/level/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  technicalSkillCategoriesIsLoadingSelector,
  technicalSkillCategoriesOptionsSelector,
  technicalSkillLevelsIsLoadingSelector,
  technicalSkillLevelsOptionsSelector,
} from 'store/v2/shared/selectors';
import getOption from 'utils/form-builder/get-option';

import {
  Paragraph,
  StyledButton,
  StyledButtonText,
  SubmitedValueTextSecondary,
  SubmittedDetails,
  SubmittedDetailsWrapper,
  SubmittedHeader,
  SubmittedValues,
  SubmittedValuesWrapper,
  Heading,
  StyledFormNotification,
  CharactersCounter,
  ErrorFormNotification,
} from '../../../Level.styled';
import { MAX_COUNT, OTHER_FIELD_ID, technicalSKillsFormInitialValues, validationSchema } from './form-config';
import { BackButton } from './TechnicalSkill.styled';

const TechnicalSkills: React.FC = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.technicalSkill);

  const isTechnicalSkillsLoading = useSelector(isTechnicalSkillsLoadingSelector);
  const isSkillLevelsOptionsLoading = useSelector(technicalSkillLevelsIsLoadingSelector);
  const skillLevelsOptions = useSelector(technicalSkillLevelsOptionsSelector);
  const skillCategoryOptions = useSelector(technicalSkillCategoriesOptionsSelector);
  const isSkillCategoryLoading = useSelector(technicalSkillCategoriesIsLoadingSelector);

  const { values, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik<TechnicalSkillsFormValues>({
      initialValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: values => {
        dispatch(
          levelActionsCreator.saveLevelFormValuesRequest(
            LEVEL_STEPS.technicalSkill,
            values,
            true,
            levelBRoutes.languages
          )
        );
      },
    });

  const handleGoBack = useCallback(() => {
    push(levelConfigs[listLevels.ONE_B].goBackConfig.url);
  }, []);

  useEffect(() => {
    dispatch(sharedActionsCreator.proficiencySkillCategoriesOptionsRequest());
    dispatch(sharedActionsCreator.proficiencyLevelsOptionsRequest());
  }, []);

  const handleProficiencyOptionChange = useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id, true);
      setFieldValue('proficiencyName', value?.name, true);
    },
    [setFieldValue]
  );

  const handleCategoryOptionChange = useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id, true);
      setFieldValue('categoryName', value?.name, true);
    },
    [setFieldValue]
  );

  const clearInputs = () => {
    setFieldValue('name', technicalSKillsFormInitialValues.name, false);
    setFieldValue('proficiency', technicalSKillsFormInitialValues.proficiency, false);
    setFieldValue('proficiencyName', technicalSKillsFormInitialValues.proficiencyName, false);
    setFieldValue('category', technicalSKillsFormInitialValues.category, false);
    setFieldValue('categoryName', technicalSKillsFormInitialValues.categoryName, false);
    setFieldValue('otherCategoryName', technicalSKillsFormInitialValues.otherCategoryName, false);
  };

  const remove = useCallback(
    (index: number) => {
      const updatedValues = values.technicalSkill.filter((_val, idx) => idx !== index);
      setFieldValue('technicalSkill', updatedValues);
    },
    [values.technicalSkill]
  );

  const handleAdd = useCallback(() => {
    const { technicalSkill, ...valuesToAdd } = values;

    setFieldValue('technicalSkill', [...technicalSkill, { ...valuesToAdd, added: true, includeOnResume: true }]);

    clearInputs();
  }, [values]);

  useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  const toggleIncludeOnResume = useCallback(
    (index: number) => {
      const updatedValues = values.technicalSkill.map((val, idx) => {
        if (idx === index) {
          const { includeOnResume, ...rest } = val;
          return {
            ...rest,
            includeOnResume: !includeOnResume,
          };
        }
        return val;
      });
      setFieldValue('technicalSkill', updatedValues);
    },
    [values.technicalSkill]
  );

  const getEnteredCharsCount = useCallback(
    inputValues => {
      const currentFormCharCount = (inputValues.name?.length || 0) + (inputValues.proficiencyName?.length || 0);
      return inputValues.technicalSkill.reduce((prev, cur) => {
        if (cur.includeOnResume) {
          const nameLen = cur.name?.length || 0;
          const profNameLen =
            cur.proficiencyName && cur.proficiencyName !== 'Proficient' ? cur.proficiencyName.length + 3 : 0;

          return prev + nameLen + profNameLen;
        }
        return prev;
      }, currentFormCharCount);
    },
    [values]
  );

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.technicalSkill, values));
  }, 600);

  const currentCount = useMemo(() => getEnteredCharsCount(values), [values]);

  return (
    <Form data-qa="computer-technical-skills" className="container px-0" onSubmit={handleSubmit}>
      <FormRow>
        <div className="col-12 d-flex">
          <Heading>Computer & Technical Skills</Heading>
        </div>
      </FormRow>
      <Paper>
        <FormRow>
          <div className="col-12">
            <Input
              className="mb-3"
              name="name"
              label="Computer/Technical Skill"
              placeholder="Enter Computer/Technical Skills"
              value={values?.name}
              error={touched?.name ? errors?.name : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12 mb-3">
            <Select
              className="mb-0"
              name="category"
              label="Skill Category"
              placeholder="Select Category"
              value={getOption(skillCategoryOptions, +(values?.category || 0), 'id')}
              error={touched?.category ? errors?.category : ''}
              options={skillCategoryOptions}
              onChange={handleCategoryOptionChange}
              isLoading={isSkillCategoryLoading}
              required={!!values.name}
              isClearable={true}
            />
            {values?.category?.toString() === OTHER_FIELD_ID.toString() && (
              <Input
                name="otherCategoryName"
                className="mt-2 mb-0"
                placeholder="Other Category (Write In)"
                value={values?.otherCategoryName}
                error={touched?.otherCategoryName ? errors?.otherCategoryName : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
          </div>
          <div className="col-12">
            <Select
              className="mb-4"
              name="proficiency"
              label="Proficiency"
              placeholder="Select Proficiency Level"
              value={getOption(skillLevelsOptions, +(values?.proficiency || 0), 'id')}
              error={touched?.proficiency ? errors?.proficiency : ''}
              options={skillLevelsOptions}
              onChange={handleProficiencyOptionChange}
              isLoading={isSkillLevelsOptionsLoading}
              required={!!values.name}
              isClearable={true}
            />
          </div>
          <div className="col-12">
            <StyledButton
              variant={ButtonVariant.secondary}
              type="button"
              disabled={
                !values?.name || !values.proficiency || !values.category || !isValid || currentCount > MAX_COUNT
              }
              onClick={handleAdd}
            >
              <StyledButtonText>Add Skill</StyledButtonText>
              <PlusSvg />
            </StyledButton>
          </div>
          {values?.technicalSkill.length >= 1 ? (
            <>
              <SubmittedHeader>Submitted Computer/Technical Skills</SubmittedHeader>
              <SubmittedDetailsWrapper>
                <SubmittedDetails>
                  <CheckboxSvg />
                  <p className="details">
                    Click to include/exclude on resume and profile. When included, the skill will be noted with a gold
                    outline.
                  </p>
                </SubmittedDetails>
                <SubmittedDetails>
                  <CancelSvg />
                  <p className="details">
                    Click to delete Computer/Technical Skill from “Submitted Computer/Technical Skills.”
                  </p>
                </SubmittedDetails>
              </SubmittedDetailsWrapper>
              <SubmittedValuesWrapper>
                <CharactersCounter error={currentCount > MAX_COUNT}>
                  {currentCount}/{MAX_COUNT}
                </CharactersCounter>
                {values?.technicalSkill?.map((value, index) => (
                  <SubmittedValues active={value.includeOnResume} key={`${value.name}${value.proficiencyName}${index}`}>
                    <div className="checkbox-wrapper">
                      <StyledCheckbox
                        type="checkbox"
                        checked={value.includeOnResume}
                        onChange={() => toggleIncludeOnResume(index)}
                      />
                      <Paragraph active={value.includeOnResume} className="truncate">
                        <span>{value.name}</span>{' '}
                        <SubmitedValueTextSecondary>({value.proficiencyName})</SubmitedValueTextSecondary>
                      </Paragraph>
                    </div>
                    <div className="svg-wrapper">
                      <button type="button" className="svg-button" title="remove" onClick={() => remove(index)}>
                        {value.includeOnResume ? (
                          <CancelYellowSvg aria-hidden="true" />
                        ) : (
                          <CancelSvg aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </SubmittedValues>
                ))}
              </SubmittedValuesWrapper>
            </>
          ) : null}
        </FormRow>
      </Paper>
      {!isValid ||
        isTechnicalSkillsLoading ||
        ((!!values?.name || !!values?.proficiency || !!values?.category) && MAX_COUNT >= currentCount && (
          <StyledFormNotification>
            You entered a skill but need to select &quot;Add Skill&quot; to move forward or clear it.
          </StyledFormNotification>
        ))}
      {MAX_COUNT < currentCount && (
        <ErrorFormNotification>Sorry, you do not have enough space left.</ErrorFormNotification>
      )}
      <FormActionButtonsRow className="mt-4">
        <BackButton
          disabled={!!values?.name || !!values?.proficiency || !!values?.category || MAX_COUNT < currentCount}
          variant={ButtonVariant.secondary}
          onClick={handleGoBack}
        >
          {levelConfigs[listLevels.ONE_B].goBackConfig.title}
        </BackButton>
        <ForwardButton
          disabled={!!values?.name || !!values?.proficiency || !!values?.category || MAX_COUNT < currentCount}
          className="col-6 col-md-3"
          type="submit"
          isLoading={isTechnicalSkillsLoading}
        />
      </FormActionButtonsRow>
    </Form>
  );
};

export default TechnicalSkills;

import * as yup from 'yup';

import { AdditionalInformationFormValues } from 'store/v2/register/types';
export const additionalInformationInitialValues: AdditionalInformationFormValues = {
  diversity: [],
  workElegibility: {
    skip: false,
  },
  includeDiversityInclusionsResume: false,
};

export const validationSchema = yup.object().shape({
  diversity: yup
    .array()
    .of(
      yup.object().shape({
        category: yup.string().required('Required.'),
        categoryName: yup.string().required('Required.'),
        status: yup.number().nullable(),
        statusName: yup.string().nullable(),
        otherStatus: yup.string().nullable(),
        added: yup.number().nullable(),
      })
    )
    .test({
      message: 'You can only select upto 12 diversities',
      test: arr => {
        if (arr && Array.isArray(arr) && arr.length <= 12) {
          return true;
        }
        return false;
      },
    }),
  workElegibility: yup.object().shape({
    skip: yup.boolean().required(),
    authorized: yup.boolean().when('skip', {
      is: skip => skip === false,
      then: yup.boolean().required('Required.'),
    }),
    work: yup.boolean().when('skip', {
      is: skip => skip === false,
      then: yup.boolean().required('Required.'),
    }),
    sponsorship: yup.boolean().when('skip', {
      is: skip => skip === false,
      then: yup.boolean().required('Required.'),
    }),
  }),
  includeDiversityInclusionsResume: yup.boolean().nullable(),
});

const getApplySections = (opportunity = {}, student = {}, sections, sectionsCompleted) => [
  {
    id: 1,
    section: sections.OPPORTUNITY_LOCATIONS,
    show: opportunity.locations.length > 1,
    completed: sectionsCompleted.opportunityLocations,
    title: 'Select a Location',
    required: opportunity.locations.length > 1,
  },
  {
    id: 2,
    section: sections.PROFILE_PICTURE,
    show: opportunity.profilePictures,
    completed: sectionsCompleted.profilePicture,
    title: 'Profile Picture',
    required: false,
  },
  {
    id: 3,
    section: sections.DIVERSITY,
    show: opportunity.diversities,
    completed: sectionsCompleted.diversity,
    title: 'Diversity',
    required: false,
  },
  {
    id: 4,
    section: sections.COURSEWORK,
    show: student && student.hasRelevantCourseWorks !== 1,
    completed: sectionsCompleted.coursework,
    title: 'Coursework',
    required: false,
  },
  {
    id: 5,
    section: sections.BULLETS_AND_BUZZWORDS,
    show: true,
    completed: sectionsCompleted.bulletsAndBuzzwords,
    title: 'Bullets & Buzzwords',
    required: true,
  },
  {
    id: 6,
    section: sections.WHY_COMPANY,
    show: true,
    completed: sectionsCompleted.whyCompany,
    title: `Add Why Company ${opportunity.whyOurCompany ? '' : '(Optional)'}`,
    required: opportunity.whyOurCompany,
  },
  {
    id: 7,
    section: sections.PORTFOLIO,
    show: true,
    completed: sectionsCompleted.portfolio,
    title: `Portfolio ${opportunity.hasPortfolio ? '' : '(Optional)'}`,
    required: opportunity.hasPortfolio,
  },
  {
    id: 8,
    section: sections.TRANSCRIPT,
    show: opportunity.hasTranscript,
    completed: sectionsCompleted.transcript,
    title: `Transcript`,
    required: opportunity.hasTranscript,
  },
  {
    id: 9,
    section: sections.EXTRAS,
    show: opportunity.hasPortfolio || opportunity.hasTranscript,
    completed: sectionsCompleted.extras,
    title: 'Extras',
    required: opportunity.hasPortfolio || opportunity.hasTranscript,
  },
  {
    id: 10,
    section: sections.PREVIEW_RESUME,
    show: true,
    completed: sectionsCompleted.previewResume,
    title: 'Preview Resume',
    required: true,
  },
];

export default getApplySections;

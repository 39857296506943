import { LEVEL_STEPS } from 'constant';
import { getDefaultExperienceLine as getCollegiateAthleticsDefaultLine } from 'pages/v2/level/1A/components/additional-details-collegiate-athletics/form-config';
import { getDefaultLine as getAdditionalDetailsDefaultLine } from 'pages/v2/level/1A/components/additional-details/form-config';
import getBoolean from 'utils/get-boolean';

import { ExperienceLine, LevelFormValues } from './types';

// TODO: type of `values` should be changed once `student` store module has correct type
// This method extracts properties from the huge `values` object(`student` object) which are required for a particular level form
export const getLevelFormValues = <T extends LEVEL_STEPS>(levelStep: T, values: any): LevelFormValues<T> => {
  switch (levelStep) {
    case LEVEL_STEPS.contactInformation:
      return {
        firstName: values?.firstName,
        middleName: values?.middleName,
        lastName: values?.lastName,
        linkedInURL: values?.linkedInURL,
        phone: values?.phone,
        internationalHome: getBoolean(values?.internationalHome) || false,
        stateOfHome: values?.stateOfHome || '', // TODO check after BE adjusting
        cityOfHome: values?.cityOfHome || '', // TODO check after BE adjusting
        countryOfHome: values?.countryOfHome || '', // TODO check after BE adjusting
        countryCityOfHome: values?.countryCityOfHome || '', // TODO check after BE adjusting
      };
    case LEVEL_STEPS.academics: {
      // Old bachelors students should not have concentrations
      const updatedBachelors = (values?.degrees?.[0] || []).map(bachelor => ({ ...bachelor, concentrations: [] }));
      // Old masters students should not have majors and minors
      const updatedMasters = (values?.degrees?.[1] || []).map(master => ({ ...master, majors: [], minors: [] }));

      return {
        degrees: [updatedBachelors, updatedMasters],
        degreeScholarships: values?.degreeScholarships || [[], []],
        degreeHonors: values?.degreeHonors || [[], []],
      };
    }
    case LEVEL_STEPS.studyAbroad: {
      return {
        studiesAbroad: values.studiesAbroad
          ? values.studiesAbroad.map(val => {
              const { international: tempInternational, includeOnResume: tempIncludeOnResume, ...rest } = val;

              const international = getBoolean(tempInternational);
              const includeOnResume = getBoolean(tempIncludeOnResume);

              return {
                ...rest,
                international,
                includeOnResume,
              };
            })
          : [],
      };
    }
    case LEVEL_STEPS.researchAndPrograms: {
      return {
        enhancedAcademicExperiences: values.enhancedAcademicExperiences?.map(val => {
          const {
            international: tempInternational,
            includeOnResume: tempIncludeOnResume,
            isThroughoutCollege: tempIsThroughoutCollege,
            remote: tempRemote,
            lines,
            ...rest
          } = val;
          const updatedLines = lines.map((line: ExperienceLine) => {
            const { enabled, isMerge, ...restLine } = line;
            return {
              enabled: getBoolean(enabled),
              isMerge: getBoolean(isMerge),
              ...restLine,
            };
          });
          const international = getBoolean(tempInternational);
          const includeOnResume = getBoolean(tempIncludeOnResume);
          const isThroughoutCollege = getBoolean(tempIsThroughoutCollege);
          const remote = getBoolean(tempRemote);

          return {
            ...rest,
            includeOnResume,
            international,
            isThroughoutCollege,
            remote,
            lines:
              (updatedLines || []).length === 0
                ? [getAdditionalDetailsDefaultLine(), getAdditionalDetailsDefaultLine()]
                : updatedLines,
          };
        }),
      };
    }
    case LEVEL_STEPS.coursework: {
      const coursework = values[LEVEL_STEPS.coursework];

      return {
        course: '',
        majorCategory: '',
        majorCategoryName: '',
        added: false,
        includeOnResume: false,
        coursework: coursework.map(val => {
          const { added: numAdded, includeOnResume: numResume, ...rest } = val;
          const added = getBoolean(numAdded);
          const includeOnResume = getBoolean(numResume);
          return {
            ...rest,
            added,
            includeOnResume,
          };
        }),
      };
    }
    case LEVEL_STEPS.collegiateAthletics: {
      const collegiateAthletics = values['d1Athletics'].map(val => {
        const { isDivisionOne: numDivisionOne, lines, ...rest } = val;
        const isDivisionOne = getBoolean(numDivisionOne);
        const updatedLines = lines.map((line: ExperienceLine) => {
          const { enabled, isMerge, ...restLine } = line;
          return {
            enabled: getBoolean(enabled),
            isMerge: getBoolean(isMerge),
            ...restLine,
          };
        });
        return {
          ...rest,
          isDivisionOne,
          // adding default values here, and two lines available initially
          lines:
            (updatedLines || []).length === 0
              ? [getCollegiateAthleticsDefaultLine(), getCollegiateAthleticsDefaultLine()]
              : updatedLines,
        };
      });
      return {
        d1Athletics: collegiateAthletics,
      };
    }
    case LEVEL_STEPS.technicalSkill: {
      const technicalSKill = values[LEVEL_STEPS.technicalSkill];

      return {
        name: '',
        category: '',
        categoryName: '',
        proficiency: '',
        proficiencyName: '',
        otherCategoryName: '',
        technicalSkill: technicalSKill,
      };
    }
    case LEVEL_STEPS.languages: {
      const languages = values[LEVEL_STEPS.languages];

      return {
        language: '',
        languageName: '',
        proficiency: '',
        proficiencyName: '',
        languages: languages,
      };
    }
    case LEVEL_STEPS.certificationsAndLicences: {
      const certificationsAndLicences = values[LEVEL_STEPS.certificationsAndLicences] || [];

      return {
        certificationOrLicense: '',
        certificationsAndLicences: certificationsAndLicences,
      };
    }
    case LEVEL_STEPS.personalInterests: {
      const personalInterests = values[LEVEL_STEPS.personalInterests] || [];

      return {
        personalInterest: '',
        personalInterests: personalInterests,
      };
    }
    case LEVEL_STEPS.diversity: {
      const diversity = values[LEVEL_STEPS.diversity] || [];

      return {
        category: null,
        categoryName: '',
        otherStatus: '',
        status: null,
        statusName: '',
        diversity: diversity,
      };
    }
    case LEVEL_STEPS.highSchool: {
      const highSchool = values[LEVEL_STEPS.highSchool] || {
        name: '',
        international: false,
        city: null,
        cityName: '',
        countryCity: '',
        country: null,
        countryName: '',
        state: null,
        stateName: '',
        monthGraduation: null,
        yearGraduation: null,
        satScore: null,
        actScore: null,
        includeHonorsOnResume: false,
        includeActSatScoreOnResume: false,
        includeOnResume: false,
      };
      const highSchoolHonors = values['highSchoolHonors'] || [];

      return {
        highSchool,
        highSchoolHonors: highSchoolHonors,
      };
    }

    default:
      return {};
  }
};

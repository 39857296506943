import styled, { css } from 'styled-components';

export const ParentCompany = styled.div`
  span {
    font-weight: bold;
  }
  i {
    font-weight: 400;
  }
  width: 75px;
  color: var(--color-white);
  font-weight: bold;
  font-size: 11px;
  margin-top: 12px;
  display: none;
  @media (max-width: 430px) {
    display: block;
  }
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      width: 100%;
      color: var(--color-white);
      font-weight: bold;
      font-size: 14px;
      margin-top: 12px;
      display: block;
      @media (max-width: 430px) {
        display: none;
      } ;
    `};
  ${({ $bsStyle }) => $bsStyle || ''};
`;

import React from 'react';

import { Input } from '../input';
import { StyledEndTag, Wrapper, StyledInput } from './InputWithCounter.styled';

interface InputWithCounterProps extends React.ComponentProps<typeof Input> {
  counterLimit: number;
}

const InputWithCounter: React.FC<InputWithCounterProps> = ({
  counterLimit,
  value,
  error,
  inputClassName,
  ...props
}) => (
  <Wrapper>
    <StyledInput value={value} error={error} inputClassName={`${inputClassName} input-counter`} {...props} />
    {counterLimit && (
      <StyledEndTag error={error}>
        {value?.toString().length}/{counterLimit}
      </StyledEndTag>
    )}
  </Wrapper>
);

export default InputWithCounter;

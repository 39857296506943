import React from 'react';

import { LEVEL_NAMES, LEVEL_STEPS, LevelRoute, levelRoutes } from 'constant';
import { levelBRoutes } from 'constant/level-configs';
import { LevelState } from 'store/v2/level/types';

import { Academics } from './1A/components/academics';
import { AdditionalDetails } from './1A/components/additional-details';
import AdditionalDetailsCollegiateAthletics from './1A/components/additional-details-collegiate-athletics';
import { CollegiateAthletics } from './1A/components/collegiate-athletics';
import { ContactInformation } from './1A/components/contact-information';
import { Coursework } from './1A/components/coursework';
import { HighSchool } from './1A/components/high-school';
import { ResearchAndPrograms } from './1A/components/ResearchAndPrograms';
import { StudyAbroad } from './1A/components/study-abroad';
import { CertificationsAndLicences } from './1B/components/certifications-and-licenses';
import { Diversities } from './1B/components/diversities';
import { Languages } from './1B/components/languages';
import { PersonalInterests } from './1B/components/personal-interests';
import { TechnicalSkills } from './1B/components/technical-skills';

interface LevelStep {
  path: LevelRoute;
  name: LEVEL_STEPS;
  levelName: LEVEL_NAMES;
  label: string;
  component: React.ComponentType;
  isDisabled: boolean | ((state: LevelState) => boolean);
}

export const level1ASteps: LevelStep[] = [
  {
    path: levelRoutes.contactInformation,
    name: LEVEL_STEPS.contactInformation,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Contact Info',
    component: ContactInformation,
    isDisabled: false,
  },
  {
    path: levelRoutes.highSchool,
    name: LEVEL_STEPS.highSchool,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'High School',
    component: HighSchool,
    isDisabled: false,
  },
  {
    path: levelRoutes.academics,
    name: LEVEL_STEPS.academics,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Academics',
    component: Academics,
    isDisabled: false,
  },
  {
    path: levelRoutes.studyAbroad,
    name: LEVEL_STEPS.studyAbroad,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Study Abroad',
    component: StudyAbroad,
    isDisabled: false,
  },
  {
    path: levelRoutes.researchAndPrograms,
    name: LEVEL_STEPS.researchAndPrograms,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Research & Programs',
    component: ResearchAndPrograms,
    isDisabled: false,
  },
  {
    path: levelRoutes.additionalDetails,
    name: LEVEL_STEPS.additionalDetails,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Additional Details',
    component: AdditionalDetails,
    isDisabled: state => {
      const researchAndProgramsState = state[LEVEL_STEPS.researchAndPrograms];
      const enhancedAcademicExperiences = researchAndProgramsState?.values?.enhancedAcademicExperiences || [];

      return (
        enhancedAcademicExperiences.length === 0 ||
        (enhancedAcademicExperiences.length > 0 &&
          enhancedAcademicExperiences?.every(({ lines }) => (lines || []).length === 0))
      );
    },
  },
  {
    path: levelRoutes.coursework,
    name: LEVEL_STEPS.coursework,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Coursework',
    component: Coursework,
    isDisabled: false,
  },
  {
    path: levelRoutes.collegiateAthletics,
    name: LEVEL_STEPS.collegiateAthletics,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Collegiate Athletics',
    component: CollegiateAthletics,
    isDisabled: false,
  },
  {
    path: levelRoutes.additionalDetailsCollegiateAthletics,
    name: LEVEL_STEPS.additionalDetailsCollegiateAthletics,
    levelName: LEVEL_NAMES.ONE_A,
    label: 'Additional Details',
    component: AdditionalDetailsCollegiateAthletics,
    isDisabled: state => {
      const collegiateAthleticsState = state[LEVEL_STEPS.collegiateAthletics];
      const d1Athletics = collegiateAthleticsState?.values?.d1Athletics || [];

      return (
        d1Athletics.length === 0 ||
        (d1Athletics.length > 0 && d1Athletics?.every(({ lines }) => (lines || []).length === 0))
      );
    },
  },
];

export const level1BSteps: LevelStep[] = [
  {
    path: levelBRoutes.technicalSkills,
    name: LEVEL_STEPS.technicalSkill,
    levelName: LEVEL_NAMES.ONE_B,
    label: 'Computer/Technical Skills',
    component: TechnicalSkills,
    isDisabled: false,
  },
  {
    path: levelBRoutes.languages,
    name: LEVEL_STEPS.languages,
    levelName: LEVEL_NAMES.ONE_B,
    label: 'Languages',
    component: Languages,
    isDisabled: false,
  },
  {
    path: levelBRoutes.certificationsAndLicenses,
    name: LEVEL_STEPS.certificationsAndLicences,
    levelName: LEVEL_NAMES.ONE_B,
    label: 'Certifications & Licenses',
    component: CertificationsAndLicences,
    isDisabled: false,
  },
  {
    path: levelBRoutes.personalInterests,
    name: LEVEL_STEPS.personalInterests,
    levelName: LEVEL_NAMES.ONE_B,
    label: 'Personal Interests',
    component: PersonalInterests,
    isDisabled: false,
  },
  {
    path: levelBRoutes.diversities,
    name: LEVEL_STEPS.diversity,
    levelName: LEVEL_NAMES.ONE_B,
    label: 'My Diversities',
    component: Diversities,
    isDisabled: false,
  },
];

export const allLevelSteps = [...level1ASteps, ...level1BSteps];

import { useFormik } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as CancelSvg } from 'assets/svg/cancel.svg';
import { ReactComponent as CheckboxSvg } from 'assets/svg/checkbox.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { ReactComponent as CancelYellowSvg } from 'assets/svg/v2/levels/cancel-yellow.svg';
import { BackButton, ButtonVariant, ForwardButton } from 'components/v2/buttons';
import { Form, FormActionButtonsRow, FormRow, Input } from 'components/v2/forms/components';
import { StyledCheckbox } from 'components/v2/forms/components/checkbox/Checkbox.styled';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS, listLevels } from 'constant';
import { levelBRoutes, levelConfigs } from 'constant/level-configs';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { CertificationsAndLicensesFormValues } from 'store/v2/level/types';

import {
  Paragraph,
  StyledButton,
  StyledButtonText,
  SubmittedDetails,
  SubmittedDetailsWrapper,
  SubmittedHeader,
  SubmittedValues,
  SubmittedValuesWrapper,
  Heading,
  StyledFormNotification,
  CharactersCounter,
  ErrorFormNotification,
} from '../../../Level.styled';
import { MAX_COUNT, certificationsFormInitialValues, validationSchema } from './form-config';

const CertificationsAndLicenses: React.FC = () => {
  const dispatch = useDispatch();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.certificationsAndLicences);

  const { values, errors, touched, isValid, handleSubmit, setFieldValue, handleChange, handleBlur } =
    useFormik<CertificationsAndLicensesFormValues>({
      initialValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: values => {
        dispatch(
          levelActionsCreator.saveLevelFormValuesRequest(
            LEVEL_STEPS.certificationsAndLicences,
            values,
            true,
            levelBRoutes.personalInterests
          )
        );
      },
    });

  const clearInputs = () => {
    setFieldValue('certificationOrLicense', certificationsFormInitialValues.certificationOrLicense, false);
  };

  const handleAdd = useCallback(() => {
    const { certificationsAndLicences, ...valuesToAdd } = values;

    setFieldValue('certificationsAndLicences', [
      ...certificationsAndLicences,
      { name: valuesToAdd.certificationOrLicense, added: true, includeOnResume: true },
    ]);

    clearInputs();
  }, [values]);

  useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  const remove = useCallback(
    (index: number) => {
      const updatedValues = values.certificationsAndLicences.filter((_val, idx) => idx !== index);
      setFieldValue('certificationsAndLicences', updatedValues);
    },
    [values.certificationsAndLicences]
  );

  const handleGoBack = useCallback(() => {
    dispatch(
      levelActionsCreator.saveLevelFormValuesRequest(
        LEVEL_STEPS.certificationsAndLicences,
        values,
        true,
        levelBRoutes.languages
      )
    );
  }, [values]);

  const toggleIncludeOnResume = useCallback(
    (index: number) => {
      const updatedValues = values.certificationsAndLicences.map((val, idx) => {
        if (idx === index) {
          const { includeOnResume, ...rest } = val;
          return {
            ...rest,
            includeOnResume: !includeOnResume,
          };
        }
        return val;
      });
      setFieldValue('certificationsAndLicences', updatedValues);
    },
    [values.certificationsAndLicences]
  );

  const getEnteredCharsCount = useCallback(
    inputValues => {
      return inputValues.certificationsAndLicences.reduce((prev, cur) => {
        return cur.includeOnResume ? prev + cur.name.length : prev;
      }, inputValues.certificationOrLicense?.length || 0);
    },
    [values]
  );

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.certificationsAndLicences, values));
  }, 600);

  const currentCount = useMemo(() => getEnteredCharsCount(values), [values]);

  return (
    <Form data-qa="certifications-licenses" className="container px-0" onSubmit={handleSubmit}>
      <FormRow>
        <div className="col-12 d-flex">
          <Heading>Certifications & Licenses</Heading>
        </div>
      </FormRow>
      <Paper>
        <FormRow>
          <div className="col-12">
            <Input
              name="certificationOrLicense"
              label="Certification or License"
              placeholder="Enter Certification or License (Write In)"
              value={values?.certificationOrLicense}
              error={touched?.certificationOrLicense ? errors?.certificationOrLicense : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12">
            <StyledButton
              variant={ButtonVariant.secondary}
              type="button"
              disabled={!values?.certificationOrLicense || !isValid || currentCount > MAX_COUNT}
              onClick={handleAdd}
            >
              <StyledButtonText>Add Certification or License</StyledButtonText>
              <PlusSvg />
            </StyledButton>
          </div>
          {values?.certificationsAndLicences?.length >= 1 ? (
            <>
              <SubmittedHeader>Submitted Certifications & Licenses</SubmittedHeader>
              <SubmittedDetailsWrapper>
                <SubmittedDetails>
                  <CheckboxSvg />
                  <p className="details">
                    Click to include/exclude on resume and profile. When included, the certification or license will be
                    noted with a gold outline.
                  </p>
                </SubmittedDetails>
                <SubmittedDetails>
                  <CancelSvg />
                  <p className="details">Click to remove from profile.</p>
                </SubmittedDetails>
              </SubmittedDetailsWrapper>
              <SubmittedValuesWrapper>
                <CharactersCounter error={currentCount > MAX_COUNT}>
                  {currentCount}/{MAX_COUNT}
                </CharactersCounter>
                {values?.certificationsAndLicences?.map((value, index) => (
                  <SubmittedValues active={value.includeOnResume} key={`${value.name}${index}`}>
                    <div className="checkbox-wrapper">
                      <StyledCheckbox
                        type="checkbox"
                        checked={value.includeOnResume}
                        onChange={() => toggleIncludeOnResume(index)}
                      />
                      <Paragraph active={value.includeOnResume} className="truncate">
                        <span>{value.name}</span>
                      </Paragraph>
                    </div>
                    <div className="svg-wrapper">
                      <button type="button" className="svg-button" title="remove" onClick={() => remove(index)}>
                        {value.includeOnResume ? (
                          <CancelYellowSvg aria-hidden="true" />
                        ) : (
                          <CancelSvg aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </SubmittedValues>
                ))}
              </SubmittedValuesWrapper>
            </>
          ) : null}
        </FormRow>
      </Paper>
      {!!values?.certificationOrLicense && MAX_COUNT >= currentCount && (
        <StyledFormNotification>
          You entered a certification/license but need to select &quot;Add Certification or License&quot; to move
          forward or clear it.
        </StyledFormNotification>
      )}
      {MAX_COUNT < currentCount && (
        <ErrorFormNotification>Sorry, you do not have enough space left.</ErrorFormNotification>
      )}
      <FormActionButtonsRow className="mt-4">
        <BackButton
          disabled={!!values?.certificationOrLicense || MAX_COUNT < currentCount}
          className="col-3"
          onClick={handleGoBack}
        >
          {levelConfigs[listLevels.ONE_B].goBackConfig.innerTitle}
        </BackButton>
        <ForwardButton
          disabled={!!values?.certificationOrLicense || MAX_COUNT < currentCount}
          className="col-6 col-md-3"
          type="submit"
        />
      </FormActionButtonsRow>
    </Form>
  );
};

export default CertificationsAndLicenses;
